<template>
  <div class="vl-parent">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      loader="spinner"
      background-color="#000"
      color="#fff"
      :opacity="0.3"
      :width="34"
      :height="34"
      :is-full-page="true"
    />
  </div>

  <div class="page-wrapper">
    <!-- ********    TOP-HEADER SECTION    ******** -->
    <TopHeader />
    <!-- ********    END TOP-HEADER SECTION    ******** -->

    <!-- ********    HEADER SECTION    ******** -->
    <div class="nav__toggle hidden"></div>
    <div class="header-section">
      <div class="top-content">
        <div></div>
        <div class="top-content-right">
          <h1 class="h1">
            Finish <span class="highlight text-second">Booking</span>
          </h1>
          <p class="p">
            Complete your booking easily and get ready for your journey.
          </p>
        </div>
      </div>
      <div
        class="journey-date bg-white-opacity text-black text-sm flex flex-row justify-between rounded-lg"
      >
        <div class="flex flex-col items-start p-3">
          <div
            class="flex flex-row gap-2 text-white text-[10px] md:text-[16px]"
          >
            <div>{{ fromName }}</div>
            <i class="bi bi-arrow-right-short text-theme text-[20px]"></i>
            <div>{{ toName }}</div>
          </div>
          <div class="text-gray-400 text-[12px] md:text-[16px]">
            <div>{{ busDetails.trip_date }}</div>
          </div>
        </div>
        <div
          @click="goHome"
          class="date-button flex flex-row justify-center items-center cursor-pointer bg-theme text-[14px] md:text-[16px] rounded-r-lg gap-2 p-3"
        >
          <i class="bi bi-house"></i>
          <button>Home</button>
        </div>
      </div>
    </div>
    <!-- ********    END HEADER SECTION    ******** -->

    <!-- ********    BODY SECTION    ******** -->
    <section class="seat-layout-section grid grid-cols-12 gap-3">
      <div class="bus-info col-span-12 md:col-span-6 lg:col-span-4">
        <!-- ****** TICKET SUMMARY ****** -->
        <div
          class="ticket-info col-span-3 lg:col-span-1 p-3 rounded-lg shadow-md w-full min-h-auto"
        >
          <div class="flex flex-row justify-between items-center mb-2">
            <div class="font-bold text-sm">TICKET SUMMARY</div>
          </div>
          <div class="w-full h-[0.7px] bg-gray-300 mb-2"></div>
          <div class="grid grid-cols-7 gap-2 text-[12px] text-gray-500 mb-2">
            <div class="col-span-2">From:</div>
            <div class="col-span-5">
              {{ busDetails.trip_route.start_point }}
            </div>
          </div>
          <div class="grid grid-cols-7 gap-2 text-[12px] text-gray-500 mb-2">
            <div class="col-span-2">To:</div>
            <div class="col-span-5">
              {{ busDetails.trip_route.end_point }}
            </div>
          </div>
          <div class="grid grid-cols-7 gap-2 text-[12px] text-gray-500 mb-2">
            <div class="col-span-2">Date:</div>
            <div class="col-span-5">{{ busDetails.trip_date }}</div>
          </div>
          <div class="grid grid-cols-7 gap-2 text-[12px] text-gray-500 mb-2">
            <div class="col-span-2">Reporting:</div>
            <div class="col-span-5">
              {{ twelveHourFormat(busDetails.reporting_time) }}
            </div>
          </div>
          <div class="grid grid-cols-7 gap-2 text-[12px] text-gray-500 mb-2">
            <div class="col-span-2">Departing:</div>
            <div class="col-span-5">
              {{ twelveHourFormat(busDetails.departing_time) }}
            </div>
          </div>
          <div class="grid grid-cols-7 gap-2 text-[12px] text-gray-500 mb-2">
            <div class="col-span-2">Arrival:</div>
            <div class="col-span-5">
              {{
                busDetails.arrival +
                " " +
                twelveHourFormat(busDetails.arrival_time)
              }}
            </div>
          </div>
          <div class="grid grid-cols-7 gap-2 text-[12px] text-gray-500">
            <div class="col-span-2">Fare:</div>
            <div class="col-span-5">{{ busDetails.fare }}/=</div>
          </div>
          <div class="w-full h-[0.7px] bg-gray-300 my-2"></div>
          <div class="flex flex-row gap-4 text-[12px] text-gray-500">
            <div class="">Payment:</div>
            <div
              v-if="paymentStatus.success"
              class="flex bg-green-500 text-white text-[11px] px-3 py-[6px] rounded-2xl"
            >
              {{ paymentStatus.message }}
            </div>
            <div
              v-else
              class="bg-gray-400 text-white text-[11px] px-3 py-[6px] rounded-2xl"
            >
              {{ paymentStatus.message ?? "Pending" }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-12 md:col-span-6 lg:col-span-8">
        <div
          class="seat-info grid-cols-3 col-span-3 lg:col-span-1 p-3 rounded-lg shadow-md w-full"
        >
          <h1 class="h1 text-theme p-2 my-3 flex items-center justify-center border rounded-md">Remaining: {{ formattedTime }} min</h1>
          <div class="font-bold text-sm">PAYMENT SUMMARY</div>
          <div class="text-sm text-gray-500 pb-2">
            Please be sure to confirm the ticket information.
          </div>
          <div class="w-full h-[0.7px] bg-gray-300 mb-2"></div>
          <div class="text-sm">PAYMENT METHOD</div>
          <div class="text-[12px] text-gray-500 pb-2">
            Choose your payment method.
          </div>
          <div class="w-full h-[0.7px] bg-gray-300 mb-3"></div>
          <form class="user-details" @submit.prevent="makePayment">
            <div class="text-sm text-gray-500 pb-1 pt-4">Payment Channel*</div>

            <div class="channels-container flex flex-col md:flex-row">
              <div
                v-for="channel in paymentChannels"
                :key="channel.mobile_channel"
                @click="selectedPaymentChannel = channel.mobile_channel"
                :class="{
                  'shadow-sm':
                    selectedPaymentChannel !== channel.mobile_channel,
                  'shadow-md shadow-no-offset border-2 border-theme':
                    selectedPaymentChannel === channel.mobile_channel,
                }"
                class="flex flex-wrap gap-3 items-center justify-between m-3 p-2 rounded-xl bg-white cursor-pointer"
              >
                <!-- Logo Image -->
                <div class="flex flex-row items-center gap-2">
                  <img
                    :src="channel.logoUrl"
                    :alt="channel.mobile_channel + ' logo'"
                    class="w-16 h-8 rounded-md"
                  />
                  <div class="text-sm">{{ channel.mobile_channel }}</div>
                </div>

                <!-- Check Icon -->
                <div>
                  <div
                    v-if="selectedPaymentChannel === channel.mobile_channel"
                    class="check-item"
                  >
                    <i class="fi fi-br-check"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-sm text-gray-500 pb-1">Phone Number*</div>
            <input
              class="p-2 mb-0"
              type="tel"
              id="phone-number"
              name="phone-number"
              v-model="phone_number"
              placeholder="07********"
              maxlength="10"
              minlength="10"
              required
            />
            <div class="text-[12px] text-gray-500 pb-8">
              Payment number for
              {{ selectedPaymentChannel }}.
            </div>

            <div class="text-[12px] text-gray-500 pb-3">
              After clicking the
              <span class="text-black font-bold">"Pay Now"</span> button, you
              will receive a request to complete the payment on your
              <span class="text-black font-bold">{{
                selectedPaymentChannel
              }}</span>
              mobile.
            </div>

            <div class="text-[12px] text-gray-500 py-3">
              Total Fare Amount:
              <span class="text-black font-bold"
                >TSH{{
                  bookings.reduce((total, booking) => total + booking.price, 0)
                }}/=</span
              >
            </div>

            <div class="w-full h-[0.7px] bg-gray-300 mb-3"></div>

            <div class="w-full flex justify-center">
              <button type="submit" class="primary-button text-[12px]">
                Pay Now
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
    <!-- ********    END BODY SECTION    ******** -->

    <!-- ********    FOOTER SECTION    ******** -->
    <section class="footer-section"><Footer /></section>
    <!-- ********    END FOOTER SECTION    ******** -->
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import Footer from "../../components/Footer.vue";
import TopHeader from "../../components/TopHeader.vue";
import CustomSwitch from "../../components/CustomSwitch.vue";
import { useToast } from "vue-toastification";

import sessionManager from "../../../core/utils/sessionManager.js";
import PaymentService from "../../../core/services/paymentService.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  data() {
    return {
      isSwitched: true,
      fromName: "",
      toName: "",
      tripDate: "",
      journeyData: {},
      journeyId: 0,
      isLoading: false,
      phone_number: "",
      paymentChannels: [],
      bookings: [],
      selectedPaymentChannel: "",
      intervalId: null,
      paymentStatus: {},
      timeLeft: 15 * 60, // 15 minutes in seconds
      timer: null,
    };
  },
  components: {
    Loading,
    Footer,
    TopHeader,
    CustomSwitch,
    Multiselect,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.timeLeft / 60);
      const seconds = this.timeLeft % 60;
      return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    },
  },
  methods: {
    goHome() {
      window.location.href = "/";
    },

    async fetchData() {
      this.isLoading = true;
      const paymentService = new PaymentService();
      await paymentService.getPaymentGatewayChannels();
      this.paymentChannels = paymentService.paymentGatewayChannelList;
      this.isLoading = false;
    },
    async makePayment() {
      if (this.selectedPaymentChannel === "") {
        this.toast.info("Select payment channel");
        return;
      }
      const paymentService = new PaymentService();
      this.isLoading = true;
      const data = {
        phone_number: this.phone_number,
        booking_number: this.bookings[0].booking_number,
        provider: this.selectedPaymentChannel,
        additionalProperties: this.bookings
          .map((booking) => booking.booking_number)
          .join(","),
      };
      await paymentService.makePayment(data);
      if (!paymentService.success.value) {
        this.toast.error(paymentService.message.value);
      } else {
        this.toast.success(paymentService.message.value);
        this.$router.push({
          path: "/ticket",
        });
      }
      this.isLoading = false;
    },
    async checkPaymentStatus() {
      const paymentService = new PaymentService();
      const data = {
        payment_status: [
          {
            booking_number: this.bookings[0].booking_number,
          },
        ],
      };
      await paymentService.checkPaymentStatus(data);
      this.paymentStatus = paymentService.paymentStatus.value;
    },

    startCountdown() {
      if (this.timer) return;

      this.timer = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          clearInterval(this.timer);
          this.timer = null;
          this.navigateToHome();
        }
      }, 1000);
    },
    navigateToHome() {
      window.location.href = "/"; // Redirect to home page
    },
  },
  mounted() {
    this.startCountdown();
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  created() {
    this.busDetails = sessionManager.getBusDetails();
    this.fromName = sessionManager.fromName.value;
    this.toName = sessionManager.toName.value;
    this.tripDate = sessionManager.date.value;
    this.bookings = sessionManager.getBookingDetails();
    this.checkPaymentStatus();
    // this.toast("Default toast");
    // this.toast.info("Info toast");
    // this.toast.success("Success toast");
    // this.toast.error("Error toast");
    // this.toast.warning("Warning toast");
    this.fetchData();
  },
};
</script>

<style scoped>
.bg-white-opacity {
  background-color: rgba(0, 0, 0, 0.5);
}
.shadow-no-offset {
  box-shadow: 0 0 10px rgba(40, 255, 51, 0.425);
}

.page-wrapper {
  overflow-x: hidden;
}

.header-section {
  position: relative;
  text-align: center;
  padding: 20px;
  height: 360px;
  background-image: url("@/assets/images/mumuk-hero.jpeg");
  background-size: cover;
  background-position: center;
  color: white;
  overflow: hidden;
}

.header-section::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Black with 50% opacity */
  z-index: 1; /* Position the overlay behind the content */
}

.header-section * {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
}

.journey-date {
  margin-left: 260px;
  margin-right: 260px;
}

.seat-layout-section {
  margin: 60px 160px;
}

.seats-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.available-bus-item {
  width: 100%;
  position: relative;
  background-color: white;
  border: #e2dedc7e 0.1px solid;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.check-item i {
  padding: 5px 4px 2px 4px;
  color: white;
  font-size: 10px;
  background-color: #22b247;
  border-radius: 12px;
}

.bus-name {
  font-size: 10px;
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.611);
  color: white;
  font-weight: 500;
}

.available-bus-item img {
  height: 200px;
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  border-radius: 16px 16px 0 0;
}

.available-bus-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px 20px;
}

@media (max-width: 1184px) {
  .seat-layout-section {
    margin: 60px 80px;
  }
  .journey-date {
    margin-left: 100px;
    margin-right: 100px;
  }
}
@media (max-width: 820px) {
  .seat-layout-section {
    margin: 40px 60px;
  }
}

@media (max-width: 768px) {
  .journey-date {
    margin-left: 0px;
    margin-right: 0px;
  }
  .seat-layout-section {
    margin: 20px 20px;
  }
  .header-section {
    height: 500px !important;
  }
}

@media (max-width: 576px) {
  .header-section {
    height: 500px !important;
  }
}
</style>
